<template>
  <form v-on:submit.prevent="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Add Participant Type</h4>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="participantTypeName">Name <span class="red-dot ml-3"></span></label>
        <input id="participantTypeName" name="participantTypeName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('participantTypeName') }"
          autocomplete="off" placeholder="Name" v-model="participantType.name" v-validate="'required'" data-vv-as="Name" />
        <div class="invalid-feedback">The Name field is required.</div>
      </div>

      <div class="form-group">
        <label for="participantTypeSortOrder">Sort Order <span class="red-dot ml-3"></span></label>
        <input id="participantTypeSortOrder" name="participantTypeSortOrder" type="text" class="form-control" :class="{ 'is-invalid': errors.has('participantTypeSortOrder') }"
          autocomplete="off" placeholder="Sort Order" v-model="participantType.sortOrder" v-validate="'required'" data-vv-as="Sort Order" />
        <div class="invalid-feedback">The Sort Order field is required.</div>
      </div>

      <div class="custom-control custom-checkbox">
        <input id="bidRequestOption" name="bidRequestOption" type="checkbox" class="custom-control-input" v-model="participantType.isBidRequestOption">
        <label for="bidRequestOption" class="custom-control-label">Is Bid Request Option</label>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

export default {
  name: 'contact-type',
  data: function () {
    return {
      isEdit: false,
      participantType: {}
    }
  },
  computed: {
    ...mapGetters(["getParticipantType"])
  },
  mounted: function() {
    this.participantType = { ...this.getParticipantType };
    this.isEdit = !!this.participantType.id;
    this.$validator.reset();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.saveParticipantType({ data: this.participantType, isEdit: this.isEdit })
        .then(() => {
          Toast.success(this, "Successfully saved!");
          this.$bvModal.hide("bv-modal-participant-type");
          this.closeModal();
        })
        .catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-participant-type");
    },
    ...mapActions(["saveParticipantType"])
  }
}
</script>
